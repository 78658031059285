<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="40%"
    >
      <el-form
        :label-position="labelPosition"
        :label-width="labelWidthForm"
        :model="model"
        @submit.native.prevent="doSubmit"
        class="form"
        ref="form"
        v-if="model"
      >
        <el-form-item
          :label="fields.password.label"
          :prop="fields.password.name"
          :required="true"
        >
          <el-col :lg="13" :md="16" :sm="24">
            <el-input
              :title="i18n('user.setPasswordAndApprove')"
              autocomplete="off"
              type="input"
              v-model="model[fields.password.name]"
            ></el-input>
          </el-col>
        </el-form-item>

        <el-form-item>
          <div class="form-buttons">
            <el-button
              :disabled="saveLoading"
              @click="doSubmit"
              icon="el-icon-fa-floppy-o"
              type="primary"
            >
              <app-i18n code="common.submit"></app-i18n>
            </el-button>

            <el-button
              :disabled="saveLoading"
              @click="doCancel"
              icon="el-icon-fa-close"
            >
              <app-i18n code="common.cancel"></app-i18n>
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { UserModel } from '@/modules/user/user-model';
import { i18n } from '@/i18n';

const { fields } = UserModel;
const formSchema = new FormSchema([fields.password]);

export default {
  name: 'app-user-password-change',

  props: ['visible'],

  data() {
    return {
      model: {},
    };
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
    }),

    dialogVisible: {
      get: function() {
        return this.visible;
      },

      set: function(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },

    formSchema() {
      return formSchema;
    },

    title() {
      return i18n('common.changePassword');
    },

    fields() {
      return fields;
    },
  },

  methods: {
    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }
      return this.$emit('success', this.model.password);
    },

    doCancel() {
      this.$emit('close');
    },

    i18n(code) {
      return i18n(code);
    },
  },
};
</script>
