<template>
  <div class="app-page-toolbar">
    <router-link
      :to="{ path: '/user/new' }"
      v-if="hasPermissionToCreate"
    >
      <el-button icon="el-icon-fa-plus" type="primary">
        <app-i18n code="common.new"></app-i18n>
      </el-button>
    </router-link>

    <el-tooltip
      :content="destroyButtonTooltip"
      :disabled="!destroyButtonTooltip"
      v-if="hasPermissionToEdit"
    >
      <span>
        <el-button
          :disabled="destroyButtonDisabled"
          @click="doDestroyAllWithConfirm()"
          icon="el-icon-fa-times"
          type="primary"
        >
          <app-i18n code="common.destroy"></app-i18n>
        </el-button>
      </span>
    </el-tooltip>

    <el-tooltip
      :content="enableButtonTooltip"
      :disabled="!enableButtonTooltip"
      v-if="hasPermissionToEdit"
    >
      <span>
        <el-button
          :disabled="enableButtonDisabled"
          @click="doEnableAllWithConfirm()"
          icon="el-icon-fa-check"
          type="primary"
        >
          <app-i18n code="user.enable"></app-i18n>
        </el-button>
      </span>
    </el-tooltip>

    <el-tooltip
      :content="disableButtonTooltip"
      :disabled="!disableButtonTooltip"
      v-if="hasPermissionToEdit"
    >
      <span>
        <el-button
          :disabled="disableButtonDisabled"
          @click="doDisableAllWithConfirm()"
          icon="el-icon-fa-ban"
          type="primary"
        >
          <app-i18n code="user.disable"></app-i18n>
        </el-button>
      </span>
    </el-tooltip>

    <el-tooltip
      :content="passwordButtonTooltip"
      :disabled="!passwordButtonTooltip"
      v-if="hasPermissionToEdit"
    >
      <span>
        <el-button
          :disabled="passwordButtonDisabled"
          @click="doOpenModal()"
          icon="el-icon-fa-ban"
          type="primary"
        >
          <app-i18n code="common.changePassword"></app-i18n>
        </el-button>
      </span>
    </el-tooltip>

    <router-link
      :to="{
        path: '/audit-logs',
        query: { entityNames: 'user' },
      }"
      v-if="hasPermissionToAuditLogs"
    >
      <el-button icon="el-icon-fa-history">
        <app-i18n code="auditLog.menu"></app-i18n>
      </el-button>
    </router-link>

    <el-tooltip
      :content="exportButtonTooltip"
      :disabled="!exportButtonTooltip"
    >
      <span>
        <el-button
          :disabled="exportButtonDisabled"
          @click="doExport()"
          icon="el-icon-fa-file-excel-o"
        >
          <app-i18n code="common.export"></app-i18n>
        </el-button>
      </span>
    </el-tooltip>

    <app-user-password-change
      :visible="dialogVisible"
      :userId="id"
      v-if="dialogVisible"
      @success="doChangePassword"
      @close="onModalClose"
    ></app-user-password-change>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { AuditLogPermissions } from '@/modules/audit-log/audit-log-permissions';
import { UserPermissions } from '@/modules/user/user-permissions';
import UserPasswordChangeModal from '@/modules/user/components/user-password-change';
import { i18n } from '@/i18n';

export default {
  name: 'app-user-list-toolbar',

  components: {
    [UserPasswordChangeModal.name]: UserPasswordChangeModal,
  },

  data() {
    return {
      id: '',
      dialogVisible: false,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      hasRows: 'user/list/hasRows',
      loading: 'user/list/loading',
      exportLoading: 'user/list/exportLoading',
      selectedRows: 'user/list/selectedRows',
    }),

    hasPermissionToAuditLogs() {
      return new AuditLogPermissions(this.currentUser).read;
    },

    hasPermissionToCreate() {
      return new UserPermissions(this.currentUser).create;
    },

    hasPermissionToEdit() {
      return new UserPermissions(this.currentUser).edit;
    },

    hasPermissionToImport() {
      return new UserPermissions(this.currentUser).import;
    },

    exportButtonDisabled() {
      return (
        !this.hasRows || this.loading || this.exportLoading
      );
    },

    exportButtonTooltip() {
      return !this.hasRows
        ? i18n('common.noDataToExport')
        : null;
    },

    destroyButtonDisabled() {
      return !this.selectedRows.length || this.loading;
    },

    destroyButtonTooltip() {
      return !this.selectedRows.length
        ? i18n('common.mustSelectARow')
        : null;
    },

    enableButtonDisabled() {
      return !this.selectedRows.length || this.loading;
    },

    enableButtonTooltip() {
      return !this.selectedRows.length
        ? i18n('common.mustSelectARow')
        : null;
    },

    disableButtonDisabled() {
      return !this.selectedRows.length || this.loading;
    },

    disableButtonTooltip() {
      return !this.selectedRows.length
        ? i18n('common.mustSelectARow')
        : null;
    },

    passwordButtonDisabled() {
      return this.selectedRows.length != 1 || this.loading;
    },

    passwordButtonTooltip() {
      return !this.selectedRows.length
        ? i18n('common.mustSelectARow')
        : null;
    },
  },

  methods: {
    ...mapActions({
      doExport: 'user/list/doExport',
      doDestroyAll: 'user/list/doDestroyAll',
      doDisableAllSelected:
        'user/list/doDisableAllSelected',
      doEnableAllSelected: 'user/list/doEnableAllSelected',
      doChangePwd: 'user/list/doChangePassword',
    }),

    async doDestroyAllWithConfirm() {
      try {
        await this.doConfirmDialog();
        return this.doDestroyAll(
          this.selectedRows.map((item) => item.id),
        );
      } catch (error) {
        //no
      }
    },

    async doDisableAllWithConfirm() {
      try {
        await this.doConfirmDialog();
        await this.doDisableAllSelected();
      } catch (error) {
        //no
      }
    },

    async doEnableAllWithConfirm() {
      try {
        await this.doConfirmDialog();
        await this.doEnableAllSelected();
      } catch (error) {
        //no
      }
    },

    async doConfirmDialog() {
      return await this.$confirm(
        i18n('common.areYouSure'),
        i18n('common.confirm'),
        {
          confirmButtonText: i18n('common.yes'),
          cancelButtonText: i18n('common.no'),
          type: 'warning',
        },
      );
    },

    async doOpenModal() {
      try {
        await this.doConfirmDialog();
        this.id = this.dialogVisible = true;
      } catch (error) {
        //no
      }
    },

    onModalClose() {
      this.dialogVisible = false;
    },

    async doChangePassword(payload) {
      try {
        this.dialogVisible = false;

        const values = {
          id: this.selectedRows[0].id,
          password: payload,
        };

        return this.doChangePwd(values);
      } catch (error) {
        //no
      }
    },
  },
};
</script>

<style></style>
