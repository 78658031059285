<template>
  <div>
    <el-table
      :border="true"
      :data="
        rows.filter(
          (data) =>
            !search ||
            data.fullName.toLowerCase().includes(search.toLowerCase()),
        )
      "
      @sort-change="doChangeSort"
      ref="table"
      row-key="id"
      v-loading="loading"
    >
      <el-table-column
        :show-overflow-tooltip="true"
        type="selection"
        width="55"
      ></el-table-column>

      <el-table-column
        :show-overflow-tooltip="true"
        :label="fields.avatars.label"
        :prop="fields.avatars.name"
        align="center"
        width="70px"
      >
        <template v-slot="scope">
          <app-list-item-image
            :value="presenter(scope.row, 'avatars')"
          ></app-list-item-image>
        </template>
      </el-table-column>

      <el-table-column
        :show-overflow-tooltip="true"
        :label="fields.email.label"
        :prop="fields.email.name"
        sortable="custom"
      >
        <template v-slot="scope">{{ presenter(scope.row, 'email') }}</template>
      </el-table-column>

      <el-table-column
        :show-overflow-tooltip="true"
        :label="fields.fullName.label"
        :prop="fields.fullName.name"
        sortable="custom"
      >
        <template v-slot="scope">{{
          presenter(scope.row, 'fullName')
        }}</template>
      </el-table-column>

      <el-table-column
        :show-overflow-tooltip="true"
        :label="fields.roles.label"
        :prop="fields.roles.name"
      >
        <template v-slot="scope">
          <div :key="roleId" v-for="roleId in scope.row.roles">
            <el-tooltip :content="roleDescriptionOf(roleId)">
              <span>{{ roleLabelOf(roleId) }}</span>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        :show-overflow-tooltip="true"
        :label="fields.status.label"
        :prop="fields.status.name"
        sortable="custom"
      >
        <template v-slot="scope">
          <el-tag :type="getStatusTagType(scope.row[fields.status.name])">{{
            presenter(scope.row, 'status')
          }}</el-tag>
        </template>
      </el-table-column>

      <el-table-column
        :show-overflow-tooltip="true"
        :label="fields.createdAt.label"
        :prop="fields.createdAt.name"
        sortable="custom"
      >
        <template v-slot="scope">{{
          presenter(scope.row, 'createdAt')
        }}</template>
      </el-table-column>

      <el-table-column
        :show-overflow-tooltip="true"
        :fixed="isMobile ? undefined : 'right'"
        align="center"
      >
        <template slot="header">
          <el-input v-model="search" size="mini" placeholder="Type to search" />
        </template>
        <template v-slot="scope">
          <div class="table-actions">
            <router-link
              :to="`/user/${scope.row.id}/edit`"
              v-if="hasPermissionToEdit"
            >
              <el-button type="text">
                <app-i18n code="common.edit"></app-i18n>
              </el-button>
            </router-link>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="el-pagination-wrapper">
      <el-pagination
        :current-page="pagination.currentPage || 1"
        :disabled="loading"
        :layout="paginationLayout"
        :total="count"
        @current-change="doChangePaginationCurrentPage"
        @size-change="doChangePaginationPageSize"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { UserModel } from '@/modules/user/user-model';
import { mapGetters, mapActions } from 'vuex';
import { UserPermissions } from '@/modules/user/user-permissions';

import Roles from '@/security/roles';

const { fields } = UserModel;

export default {
  name: 'app-user-list-table',

  data() {
    return {
      search: '',
      dialogVisible: false,
      record: null,
    };
  },

  mounted() {
    this.$watch(
      () => {
        return this.$refs.table.selection;
      },
      (rows) => {
        this.$store.dispatch('user/list/doSetSelectedRows', rows);
      },
    );
  },

  computed: {
    ...mapGetters({
      rows: 'user/list/rows',
      count: 'user/list/count',
      loading: 'user/list/loading',
      pagination: 'user/list/pagination',
      dataFetched: 'user/list/dataFetched',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      paginationLayout: 'layout/paginationLayout',
    }),

    hasPermissionToEdit() {
      return new UserPermissions(this.currentUser).edit;
    },

    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doChangeSort: 'user/list/doChangeSort',
      doChangePaginationCurrentPage: 'user/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'user/list/doChangePaginationPageSize',
      doMountTable: 'user/list/doMountTable',
      doSetSelectedRows: 'user/list/doSetSelectedRows',
    }),

    roleDescriptionOf(roleId) {
      return Roles.descriptionOf(roleId);
    },

    roleLabelOf(roleId) {
      return Roles.labelOf(roleId);
    },

    presenter(row, fieldName) {
      return UserModel.presenter(row, fieldName);
    },

    getStatusTagType(status) {
      if (status === 'enabled') {
        return 'success';
      } else if (status === 'disabled') {
        return 'danger';
      } else {
        return '';
      }
    },
  },
};
</script>

<style></style>
